<template>
  <FocusTrap>
    <div class="card" id="mycard">

      <div class="card-header header-elements-sm-inline py-sm-0" >
        <h5 class="card-title py-sm-3"><span class="icon-list"></span>&nbsp;&nbsp;Book Issue's List</h5>
        <div class="header-elements">
          <form action="#">
            <div class="form-group-feedback form-group-feedback-right">
              <input  id="txtsearch" type="search" class="form-control wmin-sm-200" placeholder="Search..." autocomplete="off">
              <div class="form-control-feedback">
                <i class="icon-search4 font-size-base text-muted"></i>
              </div>
            </div>
          </form>
        </div>
      </div>

      <div class="table-responsive">
        <table id="bookissue_table"
               class="table table-bordered table-columned"
               data-search="false"
               data-toggle="table"
               data-searchable="true"
               data-pagination="true"
               data-page-list="[10, 25, 50, 100, ALL]"
               data-target=".context-table">
          <thead>
          <tr>
            <th data-field="id" data-class="d-none">id</th>
            <th data-formatter="runningFormatter" data-width="75">S.No</th>
            <th data-field="code" data-width="108">Accession Code</th>
            <th data-field="issue_date" data-width="108">Issued On</th>
            <th data-field="reg_no" data-width="90">Regd No</th>
            <th data-field="member_name" >Name</th>
            <th data-field="book_name" >Book Title</th>
            <th data-field="author_name" data-width="300" >Authors</th>
<!--            <th data-field="book.publisher.name" data-width="200">Publisher</th>-->
          </tr>
          </thead>
          <tbody>
          </tbody>
          <tfoot>
          </tfoot>
        </table>
      </div>

      <!-- Context Menu -->
      <div id="context-menu" class="context-table">
        <div class="dropdown-menu">
          <a href="#" class="dropdown-item" @click="loadData"><i class="icon-reset" style="color: darkgreen"></i>Refresh</a>
          <div class="dropdown-divider"></div>
          <a href="#" class="dropdown-item"> <i class="icon-pencil3" ></i>Modify</a>
          <div class="dropdown-divider"></div>
          <a href="#" class="dropdown-item"> <i class="icon-blocked text-danger" ></i>Remove</a>
        </div>
      </div>
      <!-- End of Context Menu -->

      <!-- Clickable menu -->
      <ul class="fab-menu fab-menu-fixed fab-menu-bottom-right is_stuck" data-fab-toggle="hover" z-index="0">
        <li>
          <a class="fab-menu-btn btn bg-primary-600 btn-float rounded-round btn-icon" @click="showFilterWindow">
            <i class="fab-icon-open icon-filter3"></i>
            <i class="fab-icon-close icon-filter4"></i>
          </a>
        </li>
      </ul>
      <!-- /clickable menu -->

      <FocusTrap>
        <vmodal name="filter_window" draggable=".p-1" :delay="100" :resizable="true" :width="575" height="auto" focusTrap="true" @opened="afterfilterwindow_visible">
          <form class="p-1">
            <h4 >Circulation Issue Report</h4>

            <div class="form-group row">
              <label class="col-md-2 col-form-label font-weight-semibold">Department</label>
              <div class="col-md-10">
                <select  ref="cmbDepartment" class="form-control" v-model="department_id">
                  <option value="0" selected>All</option>
                  <option v-for="dept in departments" :value="dept.id">{{dept.name}}</option>
                </select>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-md-2 col-form-label font-weight-semibold">Subject</label>
              <div class="col-md-10">
                <select  ref="cmbSubject" class="form-control" v-model="subject_id">
                  <option value="0" selected>All</option>
                  <option v-for="ledger in subjects" v-bind:value="ledger.id">
                    {{ ledger.name }}
                  </option>
                </select>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-md-2 col-form-label font-weight-semibold">Section</label>
              <div class="col-md-10">
                <select  ref="cmbSection" class="form-control" v-model="section_id">
                  <option value="0" selected>All</option>
                  <option v-for="ledger in sections" v-bind:value="ledger.id">
                    {{ ledger.name }}
                  </option>
                </select>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-md-2 col-form-label font-weight-semibold">Year / Batch</label>
              <div class="col-md-10">
                <select  ref="cmbAcademicyear" class="form-control" v-model="academicyear_id">
                  <option value="0" selected>All</option>
                  <option v-for="ledger in academicyears" v-bind:value="ledger.id">
                    {{ ledger.name }}
                  </option>
                </select>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-md-2 col-form-label font-weight-semibold">Issue From</label>
              <div class="col-md-4">
                <input  ref="txtFrom" class="form-control" type="date"  style="padding: 0px;" v-model="from_date">
              </div>
              <label class="col-md-2 col-form-label font-weight-semibold">Upto</label>
              <div class="col-md-4">
                <input class="form-control  text-center" type="date" v-model="upto_date">
              </div>
            </div>


            <div class="row align-self-end">
              <div class="col-md-12 text-right">
                <button class="btn btn-outline-success" @click="loadData">Show Report <i class="icon-printer ml-2" style="color: green;"></i> </button>
              </div>

            </div>

          </form>
        </vmodal>
      </FocusTrap>

    </div>
  </FocusTrap>
</template>

<script>
import IssueForm from '@/views/lms/issue/IssueForm.vue'
import { userService } from '@/store/auth-header.js'
import { store } from '@/store/store.js'
import moment from 'moment/moment'

export default {
  name: 'IssueView',
  components: {
    IssueForm
  },
  store,
  data () {
    return {
      mytable: {},
      bookissue: JSON.parse('{"id":0,"issue_date":"2000-01-01","return_date":"2000-01-01","member_id":0,"book_id":0,"member":{"id":0,"status":"Active","type":"Student","reg_no":"","name":"","batch":"","department_id":1,"department":{"id":0,"name":""},"section_id":1,"section":{"id":0,"name":""},"email":"","mobile":"","image":""},"book":{"id":0,"status":"Active","code":"","name":"","title":"","language":"en","edition":"","edition_year":0,"publisher_id":0,"publisher_year":0,"group_id":0,"category_id":0,"rack":"","qty":0,"price":0,"pages":0,"notes":"","image":"","publisher":{"id":0,"name":""},"category":{"id":0,"name":""},"group":{"id":0,"name":""},"authors":[]},"notes":""}'),
      from_date: moment().format('YYYY-MM-DD'),
      upto_date: moment().format('YYYY-MM-DD'),
      departments : [],
      sections : [],
      subjects : [],
      academicyears:[],
      department_id : 0,
      section_id : 0,
      subject_id : 0,
      academicyear_id:0,
    }
  },
  created () {

  },
  mounted () {
    const self = this;

    this.$data.mytable = $('#bookissue_table');

    this.$data.mytable.bootstrapTable();
    $('[data-toggle="popover"]').popover();

    this.$data.mytable.contextmenu({
      target: '#context-menu',
      scopes: 'tbody > tr',
      onItem: function (row, e) {
        let id = $(row.children('*')[0]).text();

        if ($(e.target).text() === 'Modify') {
          self.modifyDocument(id);
        } else if ($(e.target).text() === 'Remove') {
          self.removeDocument(id);
        }
      }
    })

    $('#txtsearch').keyup(function () {
      let val = $.trim($(this).val()).replace(/ +/g, ' ').toLowerCase();
      $('#bookissue_table>tbody>tr').show().filter(function () {
        let text = $(this).text().replace(/\s+/g, ' ').toLowerCase();
        return !~text.indexOf(val);
      }).hide();
    })

    self.loadData()
  },
  methods: {
    afterfilterwindow_visible () {
      this.$refs.cmbDepartment.focus();
    },
    showFilterWindow () {
      this.$modal.show('filter_window');
    },
    loadFilterData () {
      const self = this;

      const requestOptions = {
        method: 'GET',
        mode: "cors",
        headers: userService.authHeader()
      };

      try {
        self.$data.departments = [];
        fetch(`${process.env.VUE_APP_ROOT_API}api/lms/departments/after/1`, requestOptions).then(userService.handleResponse).then(function (resp) {
          if (resp.ok) {
            if (_.isArray(resp.data)) {
              self.$data.departments = resp.data;
            }
          } else {
            swal({ title: "Oh noes!", text: resp.msg, type: "error" });
          }
        });

        self.$data.sections = [];
        fetch(`${process.env.VUE_APP_ROOT_API}api/lms/sections/after/1`, requestOptions).then(userService.handleResponse).then(function (resp) {
          if (resp.ok) {
            if (_.isArray(resp.data)) {
              self.$data.sections = resp.data;
            }
          } else {
            swal({ title: "Oh noes!", text: resp.msg, type: "error" });
          }
        }).catch(function (err) {

        });

        self.$data.subjects = [];
        fetch(`${process.env.VUE_APP_ROOT_API}api/lms/subjects/after/1`, requestOptions).then(userService.handleResponse).then(function (resp) {
          if (resp.ok) {
            if (_.isArray(resp.data)) {
              self.$data.subjects = resp.data;
            }
          } else {
            swal({ title: "Oh noes!", text: resp.msg, type: "error" });
          }
        }).catch(function (err) {
        });

        self.$data.sections = [];
        fetch(`${process.env.VUE_APP_ROOT_API}api/lms/sections/after/1`, requestOptions).then(userService.handleResponse).then(function (resp) {
          if (resp.ok) {
            if (_.isArray(resp.data)) {
              self.$data.sections = resp.data;
            }
          } else {
            swal({ title: "Oh noes!", text: resp.msg, type: "error" });
          }
        }).catch(function (err) {
        });

        // alert(`${process.env.VUE_APP_ROOT_API}api/lms/academicyear/after/1`);
        self.$data.academicyears = [];
        fetch(`${process.env.VUE_APP_ROOT_API}api/lms/academicyears/after/1`, requestOptions).then(userService.handleResponse).then(function (resp) {
          if (resp.ok) {
            if (_.isArray(resp.data)) {
              self.$data.academicyears = resp.data;
            }
          } else {
            swal({ title: "Oh noes!", text: resp.msg, type: "error" });
          }
        }).catch(function (err) {
          alert(err);
        });

      }catch (e) {
        alert(e);
      }
    },
    loadData(){
      const self = this;
      try {
        self.$data.showreport = false;
        self.$modal.hide('filter_window');

        const requestOptions = {
          method: 'GET',
          mode: "cors",
          headers: userService.authHeader()
        };

        self.$data.mytable.bootstrapTable('load', []);

        $('#mycard').block({
          message: '<i class="icon-spinner2 spinner"></i>',
          overlayCSS: {
            backgroundColor: '#fff',
            opacity: 0.8,
            cursor: 'wait',
            'box-shadow': '0 0 0 1px #ddd'
          },
          css: {
            border: 0,
            padding: 0,
            backgroundColor: 'none'
          }
        });

        let filters = `from_date=${self.$data.from_date}&upto_date=${self.$data.upto_date}&department_id=${self.$data.department_id}&section_id=${self.$data.section_id}&subject_id=${self.$data.subject_id}&academicyear_id=${self.$data.academicyear_id}`;

        // alert(`${process.env.VUE_APP_ROOT_API}api/lms/reports/circulation/issue?${filters}`);

        fetch(`${process.env.VUE_APP_ROOT_API}api/lms/reports/circulation/issue?${filters}`,requestOptions).then(userService.handleResponse).then(function (resp) {
          if (resp.ok) {
            if (_.isArray(resp.data)) {
              self.$data.mytable.bootstrapTable('load', resp.data);
            }
          } else {
            swal({ title: "Oops!", text: resp.msg, type: "error" });
          }
        }).catch(function (err) {
          swal({ title: "Oh noes!", text: err.toString(), type: "error" });
        }).finally(function () {
          $('#mycard').unblock();
        });

      }catch (e) {
        alert(e);
      }

    },
    modifyDocument (id) {
      const self = this;

      const requestOptions = {
        method: 'GET',
        mode: 'cors',
        headers: userService.authHeader()
      }

      self.$data.bookissue = JSON.parse('{"id":0,"issue_date":"2000-01-01","return_date":"2000-01-01","member_id":0,"book_id":0,"member":{"id":0,"status":"Active","type":"Student","reg_no":"","name":"","batch":"","department_id":1,"department":{"id":0,"name":""},"section_id":1,"section":{"id":0,"name":""},"email":"","mobile":"","image":""},"book":{"id":0,"status":"Active","code":"","name":"","title":"","language":"en","edition":"","edition_year":0,"publisher_id":0,"publisher_year":0,"group_id":0,"category_id":0,"rack":"","qty":0,"price":0,"pages":0,"notes":"","image":"","publisher":{"id":0,"name":""},"category":{"id":0,"name":""},"group":{"id":0,"name":""},"authors":[]},"notes":""}');

      fetch(`${process.env.VUE_APP_ROOT_API}api/lms/circulation/${id}`, requestOptions).then(userService.handleResponse).then(function (resp) {
        if (resp.ok) {

          self.$data.bookissue = resp.data;
          self.$modal.show('bookissue_window');
        } else {
          swal({ title: 'Oops!', text: resp.msg, type: 'error' });
        }
      }).catch(function (err) {
        swal({ title: 'Oh noes!', text: err.toString(), type: 'error' });
      })
    },
    removeDocument (id) {
      const self = this;

      self.$data.bookissue.id = id

      const requestOptions = {
        method: 'DELETE',
        mode: 'cors',
        headers: userService.authHeader(),
        body: JSON.stringify(self.$data.bookissue)
      }

      swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        type: 'warning',
        timer: 3000,
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.value) {
          fetch(`${process.env.VUE_APP_ROOT_API}api/lms/circulation/${id}`, requestOptions).then(userService.handleResponse).then(function (resp) {
            self.$modal.hide('bookissue_window');
            self.loadData();
            swal(
              'Deleted!',
              'Your request has been processed',
              'success'
            )
          }).catch(function (err) {
            swal({ title: 'Oops', text: err.toString(), type: 'error' })
          })
        }
      })
    },
  }
}
</script>

<style scoped>

</style>
